import { ContentCopy, CreditCard, CreditScore, DateRange, Diamond, Person, Pix } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Button, CardMedia, CircularProgress, Grid, InputAdornment, MenuItem, Select, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import api from "../../services/api";
import InputMask from 'react-input-mask';
import PixVerify from "../../components/PixVerify";

export default function Payment({ back, handleChangeProgress, handleCreateCompany, data }) {

    const [diamondPlan, setDiamondPlan] = useState([]);
    const [quartzPlan, setQuartzPlan] = useState([]);

    const [cardPayment, setCardPayment] = useState([]);
    const [pixPayment, setPixPayment] = useState([]);

    const [pixOpen, setPixOpen] = useState(null);
    const [pixQR, setPixQR] = useState(null);
    const [pixCopy, setPixCopy] = useState(null);
    const [pixURL, setPixURL] = useState(null);

    const [cardName, setCardName] = useState('');
    const [cardNumber, setCardNumber] = useState('');
    const [cardValidation, setCardValidation] = useState('');
    const [cardCVV, setCardCVV] = useState('');
    const [pixEmail, setPixEmail] = useState('');

    const [paymentMethod, setPaymentMethod] = useState('');

    const [accordionExpanded, setAccordionExpanded] = useState('CC');

    const [cardTimes, setCardTimes] = useState(1);

    const [plan, setPlan] = useState('');

    const [company_plan, setCompanyPlan] = useState('0');

    const [isLoadingCC, setIsLoadingCC] = useState(false);

    function getPlanProfessions() {
        api.get('lista-profissoes.php')
            .then(res => {
                if (res.data.success) {

                    let tempDiamond = res.data.profissoes.map(obj => { return obj.nome });

                    let tempQuartz = res.data.profissoes.filter(obj => obj.plano === '0').map(obj => { return obj.nome });

                    setDiamondPlan(tempDiamond);

                    setQuartzPlan(tempQuartz);

                };
            })
            .catch(err => {
                alert('Houve um erro de conexão ao carregar!');
                console.log(err);
            });
    };

    function handlePayCard() {

        if (paymentMethod !== '') {
            setIsLoadingCC(true);
            if (paymentMethod === 0) {
                let obj = {
                    "description": "Bejobs - Criação de Conta",
                    "value": plan.valor,
                    "method_payment": {
                        "number": cardNumber.replaceAll(' ', ''),
                        "exp_month": cardValidation.substring(0, 2),
                        "exp_year": cardValidation.substring(3, 7),
                        "cvv": cardCVV,
                        "holderName": cardName,
                        "vezes": cardTimes
                    }
                };

                api.post('api-pagseguro-credito.php', obj)
                    .then(res => {
                        if (res.data.success) {
                            setIsLoadingCC(false);
                            handleCreateCompany({company_plan: company_plan});
                        } else {
                            console.log(res.data);
                            alert('Houve um erro ao tentar realizar seu pagamento!');
                            setIsLoadingCC(false);
                        }
                    })
                    .catch(err => {
                        alert('Houve um erro de conexão ao tentar realizar seu pagamento!');
                        console.log(err);
                        setIsLoadingCC(false);
                    });
            } else if (paymentMethod === 1) {
                let obj = {
                    "description": "Bejobs - Criação de Conta",
                    "value": plan.valor,
                    "method_payment": {
                        "number": cardNumber.replaceAll(' ', ''),
                        "exp_month": cardValidation.substring(0, 2),
                        "exp_year": cardValidation.substring(3, 7),
                        "cvv": cardCVV,
                        "holderName": cardName
                    }
                };

                api.post('api-pagseguro-debito', obj)
                    .then(res => {
                        if (res.data.success) {
                            setIsLoadingCC(false);
                            handleCreateCompany({company_plan: company_plan});
                        } else {
                            alert('Houve um erro ao tentar realizar seu pagamento!');
                            setIsLoadingCC(false);
                        }
                    })
                    .catch(err => {
                        alert('Houve um erro de conexão ao tentar realizar seu pagamento!')
                        setIsLoadingCC(false);
                    });
            };
        };
    };

    function handlePayPix() {

        setPixOpen(true);

        let obj = {
            "value": plan.valor,
            "email": data.email,
            "method_payment": {
                "holderName": data.nome,
                "cnpj_cpf": (('02.492.213/0001-03'.replace('/', '')).replace('-', '')).replaceAll('.', '')
            }
        };

        api.post('api-pagseguro-pix.php', obj)
            .then(res => {
                if (res.data.success) {
                    setPixQR(res.data.qr_code);
                    setPixCopy(res.data.copia_cola);
                    setPixURL(res.data.consulta_pagamento)
                } else {
                    alert('Houve um erro ao tentar realizar seu pagamento!');
                }
            })
            .catch(err => {
                alert('Houve um erro de conexão ao tentar realizar seu pagamento!');
                console.log(err);
                setIsLoadingCC(false);
            });
    };

    function getValues() {

        api.get('pega-valores-plano.php')
            .then(res => {
                if (res.data.success) {

                    setCardPayment(res.data.planos.filter(obj => obj.tipo_pagamento === '0'));
                    setPixPayment(res.data.planos.filter(obj => obj.tipo_pagamento === '1'));
                };
            })
            .catch(error => {
                console.log(error);
            });
    };

    function resetPIX() {
        setPixEmail('');
        setPlan('');
        setPixCopy('');
        setPixOpen(null);
        setPixQR(null);
    };

    function resetCC() {
        setCardName('');
        setCardNumber('');
        setCardValidation('');
        setCardCVV('');
        setPaymentMethod('');
        setPlan('');
    };

    function trocarPlano() {
        setPixOpen(false);
        setPixURL(false);
    };

    useEffect(() => {
        handleChangeProgress(95);
        getPlanProfessions();
        getValues();
    }, []);

    useEffect(() => {
        pixEmail !== '' && resetCC();
        cardName !== '' && resetPIX();

    }, [pixEmail, cardName]);

    useEffect(() => {
        !plan.pode_parcelar && setCardTimes(1);
        setCompanyPlan(plan.modalidade)
    }, [plan]);

    return (
        <Grid container spacing={1}>
            {pixURL && <PixVerify url={pixURL} handleCreateCompany={handleCreateCompany} company_plan={company_plan} trocarPlano={trocarPlano} />}
            <Grid item xs={12} sm={6}>
                <Typography sx={{ mt: '1em' }} variant='h2'>Profissões de cada plano</Typography>
                <Accordion>
                    <AccordionSummary>
                        <Diamond />
                        <Typography fontWeight={700} color={"primary"} ml=".25em">
                            Plano Diamond
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Typography>
                                    O Perfil Diamond é ideal para empresas que buscam profissionais altamente qualificados e com um nível acadêmico superior. Com ele, é possível selecionar indivíduos que possuam formação universitária e experiência profissional sólida. O Perfil Diamond oferece uma ampla gama de candidatos de alto nível, permitindo que as empresas encontrem talentos especializados que atendam às suas necessidades específicas.
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sx={{ maxHeight: 250, overflow: 'auto', mt: '1rem' }}>
                                {
                                    diamondPlan?.map((obj, index) => {
                                        return (
                                            <Grid key={index} item xs={12}>
                                                <Typography fontWeight={700}>
                                                    - {obj}
                                                </Typography>
                                            </Grid>
                                        )
                                    })
                                }
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary>
                        <Typography fontWeight={700} color={"primary"} ml=".25em">
                            Plano Quartzo
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Typography>
                                    O Plano Quartzo é perfeito para empresas que desejam contratar profissionais de cursos livres para compor seu corpo profissional. Com essa opção, é possível selecionar indivíduos que possuam habilidades específicas adquiridas por meio de treinamentos e cursos práticos. O Plano Quartzo oferece uma variedade de candidatos com conhecimentos especializados, permitindo que as empresas encontrem talentos flexíveis e adaptáveis às suas demandas em constante mudança.
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sx={{ maxHeight: 250, overflow: 'auto' }}>
                                {quartzPlan?.map((obj) => {
                                    return (
                                        <Grid key={obj.index} item xs={12}>
                                            <Typography fontWeight={700}>
                                                - {obj}
                                            </Typography>
                                        </Grid>
                                    )
                                })
                                }
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            </Grid>
            <Grid item xs={12} sm={6}>
                <Typography sx={{ mt: '1em' }} variant='h2'>Pagamento</Typography>
                <Accordion
                    expanded={accordionExpanded === 'CC'}
                    onClick={() => setAccordionExpanded('CC')}
                >
                    <AccordionSummary>
                        <CreditCard color={"primary"} />
                        <Typography fontWeight={700} color={"primary"} ml=".25em">
                            Cartão de Crédito
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={6}>
                                <TextField InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="end">
                                            <Person />
                                        </InputAdornment>
                                    ),
                                }} value={cardName} onChange={e => setCardName(e.target.value)} fullWidth placeholder="Nome do Titular" />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <InputMask
                                    mask="9999 9999 9999 9999"
                                    maskChar={null}
                                    value={cardNumber} onChange={e => setCardNumber(e.target.value)}
                                >
                                    {() => <TextField
                                        variant="outlined"
                                        fullWidth placeholder="Número do Cartão"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="end">
                                                    <CreditCard />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />}
                                </InputMask>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <InputMask
                                    mask="99/9999"
                                    maskChar={null}
                                    value={cardValidation} onChange={e => setCardValidation(e.target.value)}
                                >
                                    {() => <TextField
                                        variant="outlined"
                                        fullWidth placeholder="Validade"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="end">
                                                    <DateRange />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />}
                                </InputMask>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <InputMask
                                    mask="999"
                                    maskChar={null}
                                    value={cardCVV} onChange={e => setCardCVV(e.target.value)}
                                >
                                    {() => <TextField
                                        variant="outlined"
                                        fullWidth placeholder="CVV"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="end">
                                                    <CreditScore />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />}
                                </InputMask>
                            </Grid>
                            <Grid item xs={6}>
                                <Select
                                    disabled={cardCVV === ''}
                                    fullWidth
                                    value={paymentMethod}
                                    displayEmpty
                                    onChange={e => setPaymentMethod(e.target.value)}
                                >
                                    <MenuItem value={''}>- Selecione uma modalidade -</MenuItem>
                                    <MenuItem value={0}>Crédito</MenuItem>
                                    <MenuItem value={1}>Débito</MenuItem>
                                </Select>
                            </Grid>
                            <Grid item xs={6}>
                                <Select
                                    disabled={paymentMethod === ''}
                                    fullWidth
                                    value={plan}
                                    displayEmpty
                                    onChange={e => setPlan(e.target.value)}
                                >
                                    <MenuItem value={''}>- Selecione um plano -</MenuItem>
                                    {
                                        cardPayment?.map((obj, index) => {
                                            return obj.tipo_pagamento == paymentMethod && <MenuItem key={index} value={obj}>{obj.plano}</MenuItem>
                                        })
                                    }
                                </Select>
                            </Grid>
                            {
                                paymentMethod === 0 &&
                                <Grid item xs={6}>
                                    <Select
                                        fullWidth
                                        disabled={!plan?.pode_parcelar}
                                        value={cardTimes}
                                        displayEmpty
                                        onChange={e => setCardTimes(e.target.value)}
                                    >
                                        <MenuItem value={1}>1X sem juros</MenuItem>
                                        <MenuItem value={2}>2X sem juros</MenuItem>
                                        <MenuItem value={3}>3X sem juros</MenuItem>
                                    </Select>
                                </Grid>
                            }
                            <Grid item xs={12}>
                                <Button disabled={!(paymentMethod !== '' && plan !== '') || isLoadingCC} fullWidth size="large" variant="contained" startIcon={<CreditCard />} color="success" onClick={() => handlePayCard()}>
                                    {
                                        (paymentMethod !== '' && plan !== '') ?
                                            "Pagar agora!"
                                            :
                                            "Preencha os dados acima!"
                                    }
                                </Button>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
                <Accordion
                    expanded={accordionExpanded === 'PIX'}
                    onClick={() => setAccordionExpanded('PIX')}
                >
                    <AccordionSummary>
                        <Pix color={"primary"} />
                        <Typography fontWeight={700} color={"primary"} ml=".25em">
                            PIX
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={6}>
                                <Select
                                    fullWidth
                                    value={plan}
                                    disabled={pixOpen}
                                    displayEmpty
                                    onChange={e => setPlan(e.target.value)}
                                >
                                    <MenuItem value={''}>- Selecione um plano -</MenuItem>
                                    {
                                        pixPayment?.map(obj => {
                                            return (
                                                <MenuItem key={obj.id} value={obj.valor}>{obj.plano}</MenuItem>
                                            )
                                        })
                                    }
                                </Select>
                            </Grid>
                            <Grid item xs={12}>
                                <Button disabled={plan === '' || pixOpen} fullWidth size="large" variant="contained" startIcon={<Pix />} color="success" onClick={() => { handlePayPix() }}>
                                    Pagar agora!
                                </Button>
                            </Grid>
                            <Grid item xs={12} p={'1rem'} sx={{ display: pixOpen ? 'flex' : 'none', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                {
                                    pixQR ?
                                        <CardMedia component={'img'} image={pixQR} alt="QR Code" sx={{ height: '15rem', width: '15rem', mb: '1rem' }} />
                                        :
                                        <CircularProgress sx={{ p: '8rem' }} />
                                }
                                <Typography fontWeight={600} color={'error'}>QR CODE Válido por 120 segundos!</Typography>
                                <Button sx={{ mt: '1rem' }} startIcon={<ContentCopy />} variant="contained" onClick={() => { navigator.clipboard.writeText(pixCopy) }}>
                                    Clique aqui para copiar a chave
                                </Button>
                                <Button onClick={() => trocarPlano()}>
                                    Trocar plano
                                </Button>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            </Grid>
            <Grid item xs={12} sx={{
                display: 'flex',
                justifyContent: 'space-between'
            }}>
                <Button onClick={() => { back() }}>
                    Voltar
                </Button>
            </Grid>
        </Grid>
    )
}