import { useEffect, useState } from "react";
import api from "../../services/api";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Box, CardMedia } from "@mui/material";

export default function Ads() {

    const [data, setData] = useState([]);

    useEffect(() => {
        api.get('banners.php')
            .then(res => {
                if (res.data.success) {
                    setData(res.data.banners);
                };
            })
            .catch(err => {
                alert('Ocorreu um erro ao tentar carregar os anúncios. Contate um desenvolvedor!');
                console.log(err);
            });
    }, []);

    const styles = {
        mobile: {
            display: {
                xs: 'block',
                sm: 'none'
            }
        },
        desktop: {
            display: {
                xs: 'none',
                sm: 'block'
            }
        }
    };

    return (
        <>
            <Box sx={styles.mobile}>
                <Swiper
                    autoplay
                    slidesPerView={1}
                >
                    {data.map(((obj, index) => {
                        return <SwiperSlide key={index}><CardMedia component={'img'} image={obj.imagem_mobile} alt="" /></SwiperSlide>
                    }))}
                </Swiper>
            </Box>
            <Box sx={styles.desktop}>
                <Swiper
                    autoplay
                    slidesPerView={1}
                >
                    {data.map(((obj, index) => {
                        return <SwiperSlide key={index}><CardMedia component={'img'} image={obj.imagem} alt="" /></SwiperSlide>
                    }))}
                </Swiper>
            </Box>
        </>
    );
};