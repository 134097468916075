import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

export default function Error() {

  const styles = {
    container: {
      color: 'white',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      m: 'auto'
    },
    button: {
      mt: '1rem',
      color: 'white'
    }
  };

  return (
    <Box sx={styles.container}>
      <Typography variant="h2" fontSize={'4rem'} textAlign={'center'} >
        Erro 404
      </Typography>
      <Typography fontSize={'2rem'} mt={'.5em'}>
        Ops, esta página não foi encontrada!
      </Typography>
      <Button component={Link} to="/" variant="text" sx={styles.button}>
        Voltar para a home
      </Button>
    </Box>
  );
};