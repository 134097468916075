import { CheckCircle, Password } from "@mui/icons-material";
import { Button, Card, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { Link } from "react-router-dom";
import api from "../services/api";

export default function ResetPassword() {

  const [page, setPage] = useState(0);

  const [userId, setUserID] = useState('');

  const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const styles = {
    card: {
      p: '2rem',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      p: '2rem',
      m: '1rem'
    },
    icon: {
      fontSize: '80px',
      mb: '1rem'
    },
    textField: {
      mb: '1rem'
    }
  };

  function DefineEmail() {

    const [email, setEmail] = useState('');

    function sendToken() {
      if (regex.test(email)) {
        api.post('envia-codigo-troca-senha.php', { email })
          .then(res => {
            if (res.data.success) {
              setPage(page + 1)
            } else {
              alert('Erro ao enviar seu email! Entre em contato com o desenvolvedor.');
            };
          })
          .catch(err => {
            alert('Ocorreu um erro de rede! Entre em contato com o desenvolvedor.');
            console.log(err);
          });
      } else {
        alert('Por favor, insira um email válido!');
      };
    };

    return (
      <Card sx={styles.card}>
        <Password sx={styles.icon} />
        <Typography variant="h2" mb='1rem'>
          Redefinição de Senha
        </Typography>
        <Typography mb='1rem'>
          Insira o email da conta que deseja redefinir a senha.
        </Typography>

        <TextField fullWidth placeholder="Email" value={email} onChange={e => setEmail(e.target.value)} sx={styles.textField} />

        <Button fullWidth onClick={() => sendToken()} variant="contained">Avançar</Button>
      </Card>
    );
  };

  function VerifyToken() {

    const [token, setToken] = useState('');

    function compareToken() {
      if (token !== '') {
        api.post('verifica-token.php', { token })
          .then(res => {
            if (res.data.success && res.data.token_exists) {
              setUserID(res.data.id_usuario)
              setPage(page + 1)
            } else {
              alert('Token inválido!');
            };
          })
          .catch(err => {
            alert('Ocorreu um erro de rede! Entre em contato com o desenvolvedor.');
            console.log(err);
          });
      } else {
        alert('Por favor, insira um token válido!');
      };
    };

    return (
      <Card sx={styles.card}>
        <Password sx={styles.icon} />
        <Typography variant="h2" mb='1rem'>
          Redefinição de Senha
        </Typography>
        <Typography mb='1rem'>
          Insira o token que você recebeu via email
        </Typography>

        <TextField fullWidth placeholder="Token" value={token} onChange={e => setToken(e.target.value)} sx={styles.textField} />

        <Button fullWidth onClick={() => compareToken()} variant="contained">Avançar</Button>
      </Card>
    );
  };

  function ChangePassword() {

    const [password, setPassword] = useState('');
    const [repassword, setRepassword] = useState('');

    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/;

    function resetPassword() {
      if (regex.test(password)) {
        if (password === repassword) {
          api.post('troca-senha.php', { senha: password, id_usuario: userId })
            .then(res => {
              if (res.data.success) {
                setPage(page + 1);
              } else {
                alert('Ocorreu um erro ao alterar a senha. Tente novamente.');
              }
            }).catch(err => [
              alert('Ocorreu um erro de rede! Tente novamente mais tarde.')
            ]);
        } else {
          alert('Senha e verificação de senha devem ser identicas!');
        };
      } else {
        alert('Sua nova senha deve conter ao menos um caracter especial, número, letra minúscula e letra maiúscula!');
      };
    };

    return (
      <Card sx={styles.card}>
        <Password sx={styles.icon} />
        <Typography variant="h2" mb='1rem'>
          Redefinição de Senha
        </Typography>
        <Typography mb='1rem'>
          Insira uma nova senha
        </Typography>

        <TextField type="password" fullWidth placeholder="Senha" value={password} onChange={e => setPassword(e.target.value)} sx={styles.textField} />
        <TextField type="password" fullWidth placeholder="Repita sua senha" value={repassword} onChange={e => setRepassword(e.target.value)} sx={styles.textField} />

        <Button fullWidth onClick={() => resetPassword()} variant="contained">Alterar senha</Button>
      </Card>
    );
  };

  function Result() {

    return (
      <Card sx={styles.card}>
        <CheckCircle color="success" sx={styles.icon} />
        <Typography variant="h2" mb='1rem'>
          Redefinição de Senha
        </Typography>
        <Typography mb='1rem'>
          'Sua senha foi alterada! Realize seu login agora mesmo.'
        </Typography>
        <Button fullWidth component={Link} to="/Login" variant="contained">Ir para login</Button>
      </Card>
    );
  };

  function Error() {
    return (
      <Card sx={styles.card}>
        <Error color="error" sx={styles.icon} />
        <Typography variant="h2" mb='1rem'>
          Redefinição de Senha
        </Typography>
        <Typography mb='1rem'>
          Erro ao tentar alterar sua senha. Entre em contato com o suporte
        </Typography>
        <Button variant="contained">Ir para login</Button>
      </Card>
    );
  };

  switch (page) {
    case 0:
      return <DefineEmail />
    case 1:
      return <VerifyToken />
    case 2:
      return <ChangePassword />
    case 3:
      return <Result />
    default:
      return <Error />
  };
};