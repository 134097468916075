import api from '../services/api';

function PixVerify({ url, handleCreateCompany, trocarPlano, company_plan }) {

    let control = true;
    let number = 120;

    function cancelarVerificacao() {
        control = false;
        number = 0;
    };

    function verificaPagamento() {
        if (control && number > 0) {

            const interval = setInterval(() => {

                if (number <= 0) {

                    clearInterval(interval);
                    cancelarVerificacao();
                    trocarPlano();

                    return;
                };

                api.get(url)
                    .then(response => {
                        const { success, pago } = response.data;

                        if (success && pago) {
                            handleCreateCompany({company_plan: company_plan});
                            cancelarVerificacao();
                        } else {
                            number--;
                        };
                    })
                    .catch(error => {
                        console.error('Erro na solicitação:', error);
                        trocarPlano();
                        cancelarVerificacao();
                    });
            }, 1000);
        } else {
            cancelarVerificacao();
        };
    };

    verificaPagamento();

    return null;
};

export default PixVerify;