import { Business, Person } from "@mui/icons-material";
import { Button, Grid, Typography } from "@mui/material";
import { useEffect } from "react";

export default function Profile({ handleChangeUser, handleChangeProgress }) {

    const styles = {
        icon: {
            fontSize: '80px'
        },
        button: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            boxShadow: '3px 3px 15px 2px',
            height: '100%',
            p: '2em',

        }
    };

    function setType(type) {
        handleChangeUser(type);
    };

    useEffect(() => {
        handleChangeProgress(0);
    }, [])

    return (
        <Grid container>
            <Grid item xs={12} sm={6} p={'1em'}>
                <Button fullWidth sx={styles.button} onClick={() => setType('0')}>
                    <Person sx={styles.icon} />
                    <Typography variant={'h2'} mt=".5em">
                        Profissional
                    </Typography>
                    <Typography mt=".5em" width={'70%'}>
                        Quer encontrar uma vaga que combina com você? Cadastre se agora e tenha na palma da mão o emprego dos seus sonhos. Perfil de manicures, lash designers, médicos e dentistas, por exemplo.
                    </Typography>
                </Button>
            </Grid>

            <Grid item xs={12} sm={6} p={'1em'}>
                <Button fullWidth sx={styles.button} onClick={() => setType('1')}>
                    <Business sx={styles.icon} />
                    <Typography variant={'h2'} mt=".5em">
                        Instituição
                    </Typography>
                    <Typography mt=".5em" width={'70%'}>
                        Você empresário do ramo da beleza, estética e saúde está cansado de procurar profissionais em sites não especializados? Cadastre-se agora! Perfil de clínicas estéticas, salões de beleza e consultórios médicos, por exemplo.
                    </Typography>
                </Button>
            </Grid>
        </Grid>
    )
}