import { Button, Checkbox, Chip, FormControl, Grid, Input, InputAdornment, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import 'moment/locale/pt-br';
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { useEffect } from "react";
import { useState } from "react";
import InputMask from 'react-input-mask';
import api from "../../services/api";

export default function PersonalData({ back, handleChangeProgress, handleChangeUserData, userType }) {

    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/;

    const [title, setTitle] = useState('');

    const [nome, setNome] = useState('');

    const [rSocial, setRSocial] = useState('');

    const [nascimento, setNascimento] = useState('');
    const [sexo, setSexo] = useState('');

    const [CPF, setCPF] = useState('');
    const [CPFVerify, setCPFVerify] = useState(false);

    const [RG, setRG] = useState('');
    const [RGVerify, setRGVerify] = useState(false);

    const [estrangeiro, setEstrangeiro] = useState(false);
    const [docInternacional, setDocInternacional] = useState('');

    const [CNPJ, setCNPJ] = useState('');
    const [CNPJVerify, setCNPJVerify] = useState(false);

    const [portfoil, setPortfoil] = useState('');

    const [professions, setProfessions] = useState([]);

    const [userProfession, setUserProfession] = useState([]);

    const [senha, setSenha] = useState('');
    const [confSenha, setConfSenha] = useState('');

    function changeUserData() {

        if (verifyData()) {
            if (senha === confSenha) {
                if (regex.test(senha)) {
                    if (userType === '0') {
                        var basicData = {
                            nome,
                            nascimento,
                            sexo,
                            senha,
                            profession: userProfession
                        };

                        if (estrangeiro) {
                            var data = {
                                ...basicData,
                                estrangeiro,
                                docInternacional
                            };
                        } else {
                            var data = {
                                ...basicData,
                                CPF,
                                RG
                            };
                        };

                    } else {
                        var data = {
                            nome,
                            razao_social: rSocial,
                            CNPJ,
                            senha
                        };
                    };
                    handleChangeUserData(data);
                } else {
                    alert('Sua senha deve conter ao menos um número, uma letra maiúscula, uma letra minúscula e um caractere especial!');
                };
            } else {
                alert('Sua senha e confirmação de senha devem ser iguais!');
            };
        } else {
            alert('Por favor, preencha corretamente todos os dados obrigatórios!');
        };
    };

    function verifyData() {
        if (nome !== '' && (userType === '0' ?
            (nascimento !== '' && sexo !== '' && userProfession.length > 0 && (estrangeiro ?
                (docInternacional !== '')
                :
                (CPF !== '' && RG !== '' && !CPFVerify && !RGVerify)))
            :
            (rSocial !== '' && CNPJ !== '' && !CNPJVerify && CNPJ.length === 18))) {
            return true;
        } else {
            return false;
        };
    };

    function getProfession() {
        api.get('lista-profissoes.php')
            .then(res => {
                if (res.data.success) {
                    setProfessions(res.data.profissoes);
                } else {
                    alert('Houve um erro ao tentar carregar as profissões! Tente novamente mais tarde.')
                };
            })
            .catch(error => {
                alert('Houve um erro ao tentar carregar as profissões! Tente novamente mais tarde.')
                console.log(error);
            });
    };

    function checkCNPJ() {
        api.post('verifica-cnpj.php', { cnpj: CNPJ })
            .then(res => {
                if (res.data.success) {
                    if (res.data.exists) {
                        alert('Ops, já existe uma conta com este CNPJ! Realize seu login.');
                        setCNPJVerify(true);
                    } else {
                        setCNPJVerify(false);
                    };
                } else {
                    alert('Houve um erro ao tentar verificar seu CNPJ! Tente novamente mais tarde.');
                    setCNPJVerify(true);
                };
            })
            .catch(err => {
                alert('Houve um erro ao tentar verificar seu CNPJ! Tente novamente mais tarde.');
                console.log(err);
                setCNPJVerify(true);
            });
    };

    function checkCPF() {
        api.post('verifica-cpf.php', { cpf: CPF })
            .then(res => {
                if (res.data.success) {
                    if (res.data.exists) {
                        alert('Ops, já existe uma conta com este CPF! Realize seu login.');
                        setCPFVerify(true);
                    } else {
                        setCPFVerify(false);
                    };
                } else {
                    alert('Houve um erro ao tentar verificar seu CPF! Tente novamente mais tarde.');
                    setCPFVerify(true);
                };
            })
            .catch(err => {
                alert('Houve um erro ao tentar verificar seu CNPJ! Tente novamente mais tarde.');
                console.log(err);
                setCNPJVerify(true);
            });
    };

    function checkRG() {
        api.post('verifica-rg.php', { rg: RG })
            .then(res => {
                if (res.data.success) {
                    if (res.data.exists) {
                        alert('Ops, já existe uma conta com este RG! Realize seu login.');
                        setRGVerify(true);
                    } else {
                        setRGVerify(false);
                    };
                } else {
                    alert('Houve um erro ao tentar verificar seu RG! Tente novamente mais tarde.');
                    setRGVerify(true);
                };
            })
            .catch(err => {
                alert('Houve um erro ao tentar verificar seu CNPJ! Tente novamente mais tarde.');
                console.log(err);
                setCNPJVerify(true);
            });
    };

    useEffect(() => {
        handleChangeProgress(25);
        getProfession();
        setTitle(userType === '0' ? 'Pessoais' : 'Empresariais');
    }, []);

    useEffect(() => {
        CNPJ.length === 18 && checkCNPJ();
    }, [CNPJ]);

    useEffect(() => {
        CPF.length === 14 && checkCPF();
    }, [CPF]);

    useEffect(() => {
        RG.length === 10 && checkRG();
    }, [RG]);

    function changeProfessionList(obj) {

        const list = userProfession.filter(item => obj !== item);

        setUserProfession(list);

    };

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Typography sx={{ mt: '1em' }} variant='h2'>Informações {title}</Typography>
            </Grid>

            <Grid item xs={12} sm={6}>
                <TextField fullWidth label={userType === '1' ? 'Nome Fantasia*' : 'Nome*'} value={nome} onChange={(e) => setNome(e.target.value)} />
            </Grid>

            {
                userType === '1' &&
                <>
                    <Grid item xs={12} sm={6}>
                        <TextField fullWidth label="Razão Social*" value={rSocial} onChange={(e) => setRSocial(e.target.value)} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <InputMask
                            mask="99.999.999/9999-99"
                            maskChar={null}
                            onChange={e => setCNPJ(e.target.value)}
                            value={CNPJ}
                        >
                            {() => <TextField
                                variant="outlined"
                                fullWidth
                                label='CNPJ*'
                            />}
                        </InputMask>
                    </Grid>
                </>
            }

            {
                userType === '0' &&
                <>
                    <Grid item xs={6} sm={3}>
                        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={'pt-br'}>
                            <MobileDatePicker
                                label="Data de Nascimento"
                                sx={{ width: '100%' }}
                                value={nascimento}
                                onChange={(newValue) => {
                                    setNascimento(newValue);
                                }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                    </Grid>

                    <Grid item xs={6} sm={3}>
                        <FormControl fullWidth>
                            <InputLabel>Sexo</InputLabel>
                            <Select
                                fullWidth
                                value={sexo}
                                label="Sexo*"
                                onChange={e => setSexo(e.target.value)}
                            >
                                <MenuItem value={''}>- Selecionar Sexo -</MenuItem>
                                <MenuItem value={'M'}>Masculino</MenuItem>
                                <MenuItem value={'F'}>Feminino</MenuItem>
                                <MenuItem value={'No'}>Não responder</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={3} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Checkbox checked={estrangeiro} onChange={() => setEstrangeiro(!estrangeiro)} />
                        <Typography>
                            Documento Estrangeiro
                        </Typography>

                    </Grid>

                    {
                        estrangeiro ?
                            <Grid item xs={12} sm={6}>
                                <TextField fullWidth label="Documento" value={docInternacional} onChange={(e) => setDocInternacional(e.target.value)} />
                            </Grid>
                            :
                            <>
                                <Grid item xs={12} sm={3}>
                                    <InputMask
                                        mask="999.999.999-99"
                                        maskChar={null}
                                        onChange={e => setCPF(e.target.value)}
                                        value={CPF}
                                    >
                                        {() => <TextField
                                            variant="outlined"
                                            fullWidth
                                            label='CPF*'
                                        />}
                                    </InputMask>
                                </Grid>

                                <Grid item xs={12} sm={3}>
                                    <InputMask
                                        mask="9999999999"
                                        maskChar={null}
                                        onChange={e => setRG(e.target.value)}
                                        value={RG}
                                    >
                                        {() => <TextField
                                            variant="outlined"
                                            fullWidth
                                            label='RG*'
                                        />}
                                    </InputMask>
                                </Grid>
                            </>
                    }

                    <Grid item xs={12}>
                        <Typography sx={{ mt: '1em' }} variant='h2'>Portfólio</Typography>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <TextField fullWidth label="Portfólio" value={portfoil} onChange={(e) => setPortfoil(e.target.value)} helperText="Insira aqui, um link que redirecione para seu portfólio" />
                    </Grid>

                    <Grid item xs={12}>
                        <Typography sx={{ mt: '1em' }} variant='h2'>Qual sua área de atuação profissional?</Typography>
                    </Grid>

                    <Grid item xs={12}>
                        {
                            userProfession.length > 0 ?
                                userProfession?.map((obj, index) => {
                                    return <Chip key={index} color="primary" sx={{ m: '.25em' }} label={obj} variant="filled" onDelete={() => changeProfessionList(obj)} />
                                })
                                :
                                <Chip color="error" variant="filled" label={'Você ainda não incluiu especialidades'} />
                        }
                    </Grid>

                    <Grid item xs={6} sm={3}>
                        <FormControl fullWidth>
                            <Select
                                value={''}
                                onChange={(e) => setUserProfession(oldData => [...oldData, e.target.value])}
                                fullWidth
                                displayEmpty
                                MenuProps={{ PaperProps: { sx: { maxHeight: 225 } } }}
                                inputProps={{
                                    'aria-label': 'Without label'
                                }}
                            >
                                <MenuItem value={''}>- Adicionar especialidade -</MenuItem>
                                {
                                    professions.map((obj) => {
                                        return !userProfession.includes(obj.nome) && (
                                            <MenuItem key={obj.id} value={obj.nome}>{obj.nome}</MenuItem>
                                        );
                                    })
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                </>
            }
            <Grid item xs={12}>
                <Typography sx={{ mt: '1em' }} variant='h2'>Senha</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField type='password' fullWidth label="Senha*" value={senha} onChange={(e) => setSenha(e.target.value)} />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField type='password' fullWidth label="Confirmar Senha*" value={confSenha} onChange={(e) => setConfSenha(e.target.value)} />
            </Grid>

            <Grid item xs={12} sx={{
                display: 'flex',
                justifyContent: 'space-between'
            }}>
                <Button onClick={() => back()}>
                    Voltar
                </Button>
                <Button size="large" variant="contained" onClick={() => changeUserData()}>
                    Avançar
                </Button>
            </Grid>
        </Grid>
    );
};