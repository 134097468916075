import {
    React,
    useContext,
    useEffect,
    useState,
} from 'react';

import { Box, Button, Card, CardMedia, Chip, createTheme, FormControl, Grid, IconButton, InputAdornment, InputLabel, MenuItem, Select, TextField, Typography, useTheme } from '@mui/material';
import { AddLink, Clear, Delete, Edit, Facebook, Instagram, LinkedIn, Upload } from '@mui/icons-material';
import InputMask from 'react-input-mask';
import AuthContext from '../contexts/AuthProvider';
import api from '../services/api';
import getUFCity from '../components/getUFCity';

export default function EditProfile() {

    const defaultImageURL = "https://bejobs.com.br/images/perfis/default.jpg";

    const [message, setMessage] = useState('');

    const [nome, setNome] = useState('');
    const [razaoSocial, setRazaoSocial] = useState('');
    const [celular, setCelular] = useState('');
    const [nascimento, setNascimento] = useState('');
    const [sexo, setSexo] = useState('');
    const [email, setEmail] = useState('');

    const [docEstrangeiro, setDocEstrangeiro] = useState('');
    const [CNPJ, setCNPJ] = useState('');
    const [CPF, setCPF] = useState('');
    const [RG, setRG] = useState('');

    const [professions, setProfessions] = useState([]);
    const [userProfession, setUserProfession] = useState([]);

    const [portfolio, setPortfolio] = useState('');

    const [galleryImages, setGalleryImages] = useState([]);
    const [galleryImagesURL, setGalleryImagesURL] = useState([]);

    const [instagram, setInstagram] = useState('');
    const [facebook, setFacebook] = useState('');
    const [linkedin, setLinkedin] = useState('');

    const [endereco, setEndereco] = useState('');
    const [complemento, setComplemento] = useState('');
    const [number, setNumber] = useState('');
    const [bairro, setBairro] = useState('');
    const [CEP, setCEP] = useState('');

    const [city, setCity] = useState(0);
    const [cityList, setCityList] = useState([]);

    const [state, setState] = useState(0);
    const [stateList, setStateList] = useState([]);

    const [userImage, setUserImage] = useState(defaultImageURL);

    const [senha, setSenha] = useState('');
    const [conf_senha, setConfSenha] = useState('');

    const { userData, userType, refreshUserData } = useContext(AuthContext);

    const theme = useTheme();

    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/;

    function onFileChange(e) {
        let files = e.target.files;

        let fileReader = new FileReader();

        fileReader.readAsDataURL(files[0]);
        fileReader.onload = (event) => {
            setUserImage(event.target.result)
        }
    };

    const valueByType = (first, second) => {
        if (userType === '0') {
            return first;
        } else {
            return second;
        };
    };

    function getData() {
        getUFCity(state !== 0 && state)
            .then(res => {
                let res_list = res.data;
                let temp_list = [];
                for (let i = 0; i < res_list.length; i++) {

                    if (state !== 0) {
                        temp_list?.push(res_list[i].nome);
                    } else {
                        temp_list?.push(res_list[i].sigla);
                    };
                }

                if (state === 0) {
                    setStateList(temp_list);
                } else {
                    setCityList(temp_list);
                }

                return temp_list;

            })
            .catch(err => {
                alert('Ocorreu um erro ao carregar sua página home!');
                console.log(err);
            });
    };

    const refreshProfissional = () => {
        setNome(userData.nome);
        setCelular(userData.numero);
        setNascimento(userData.nascimento);
        setSexo(userData.sexo);
        setEmail(userData.email);
        setCPF(userData.cpf);
        setRG(userData.rg);
        setEndereco(userData.logradouro);
        setComplemento(userData.complemento);
        setNumber(userData.numero);
        setBairro(userData.bairro);
        setCEP(userData.cep);
        setCity(userData.cidade);
        setState(userData.estado);
        setPortfolio(userData.portfolio);
        setInstagram(userData.instagram);
        setFacebook(userData.facebook);
        setLinkedin(userData.linkedin);
        setUserProfession(userData.profissao);
        setGalleryImagesURL(userData.galleryImage);
    };

    function getProfession() {
        api.get('lista-profissoes.php')
            .then(res => {
                if (res.data.success) {
                    setProfessions(res.data.profissoes);
                } else {
                    alert('Houve um erro ao tentar carregar as profissões! Tente novamente mais tarde.')
                };
            })
            .catch(error => {
                alert('Houve um erro ao tentar carregar as profissões! Tente novamente mais tarde.')
                console.log(error);
            });
    };

    const refreshEmpresa = () => {
        setNome(userData.nome);
        setRazaoSocial(userData.razao_social);
        setCelular(userData.telefone);
        setEmail(userData.email);
        setCNPJ(userData.cnpj);
        setEndereco(userData.logradouro);
        setComplemento(userData.complemento);
        setNumber(userData.numero);
        setBairro(userData.bairro);
        setCEP(userData.cep);
        setCity(userData.cidade);
        setState(userData.estado);
        userData.userImage && setUserImage(userData.userImage);
    };

    function verifyData() {
        if (userType === '1') {
            if (nome !== '' && razaoSocial !== '' && CNPJ !== '' && email !== '' && celular !== '' && endereco !== '' && complemento !== '' && number !== '' && bairro !== '' && CEP !== '' && state !== '' && city !== '') {
                return true;
            } else {
                return false;
            };
        } else {
            if (nome !== '' && nascimento !== '' && sexo !== '' && CPF !== '' && RG !== '' && endereco !== '' && complemento !== '' && number !== '' && bairro !== '' && CEP !== '' && state !== '' && city !== '' && celular !== '' && email !== '') {
                return true;
            } else {
                return false;
            };
        };
    };

    function changeUserData() {

        if (senha === conf_senha) {
            if (passwordRegex.test(senha) || senha === '') {
                if (verifyData()) {
                    if (emailRegex.test(email)) {
                        if (celular.length >= 11) {

                            const payload = {
                                "userType": userType,
                                "estrangeiro": docEstrangeiro,
                                "id_user": userData.id,
                                "userData": {
                                    "nome": nome,
                                    "razao_social": razaoSocial,
                                    "nascimento": nascimento,
                                    "sexo": sexo,
                                    "email": email,
                                    "phone": celular,
                                    "address": endereco,
                                    "complement": complemento,
                                    "number": number,
                                    "location": bairro,
                                    "CPF": CPF,
                                    "CNPJ": CNPJ,
                                    "RG": RG,
                                    "CEP": CEP.replace('.', '').replace('-', ''),
                                    "portfoil": portfolio,
                                    "facebook": facebook,
                                    "instagram": instagram,
                                    "linkedin": linkedin,
                                    "state": state,
                                    "city": city,
                                    "profession": userProfession,
                                    "senha": senha,
                                    "userImage": userImage !== defaultImageURL && userImage,
                                    "galleryImages": galleryImages
                                }
                            };
                            api.post('editar-usuario.php', payload)
                                .then(res => {
                                    if (res.data.success) {
                                        alert('Dados editados com sucesso!');
                                    } else {
                                        alert(res.data.msg);
                                    };
                                })
                                .catch(err => {
                                    alert('Houve um erro de conexão! Tente novamente mais tarde.');
                                    console.log(err);
                                });
                        } else {
                            alert('Para registrar-se, você deve preencher completamente seu número de telefone!');
                        };
                    } else {
                        alert('Para registrar-se, você deve inserir um email válido!');
                    };
                } else {
                    alert('Por favor, preencha todos os dados obrigatórios!');
                };
            } else {
                alert('Sua senha deve conter ao menos um número, uma letra maiúscula, uma letra minúscula e um caractere especial!');
            };
        } else {
            alert('As senhas inseridas não coincidem!');
        };
    };

    function deleteImage(id) {

        let payload = {
            user_type: userType,
            user_id: userData.id,
            image_id: id && id.toString()
        };

        api.post('delete-image.php', payload)
            .then(res => {
                if (res.data.success) {
                    alert('Imagem excluída com sucesso!');
                    setUserImage('https://bejobs.com.br/images/perfis/default.jpg');
                } else {
                    alert('Erro ao tentar excluir sua imagem! Tente novamente mais tarde.');
                };
            })
            .catch(err => {
                alert('Erro de conexão! Tente novamente mais tarde.');
                console.log(err);
            });
    };

    function deleteGalleryImage(index, id) {

        let images_preview = galleryImagesURL;

        let images = galleryImagesURL;

        images_preview = images_preview.filter(obj => obj !== images_preview[index]);

        images = images.filter(obj => obj !== images[index]);

        deleteImage(id);

        setGalleryImagesURL(images_preview);
        setGalleryImages(images);
    };

    function changeProfessionList(obj) {

        const list = userProfession.filter(item => obj !== item);

        setUserProfession(list);

    };

    function onGalleryChange(e) {

        let files = e.target.files;

        let image_preview = URL.createObjectURL(files[0]);

        setGalleryImagesURL(oldData => [...oldData, image_preview]);

        let fileReader = new FileReader();

        fileReader.readAsDataURL(files[0]);
        fileReader.onload = (event) => {
            let image = event.target.result

            setGalleryImages(oldData => [...oldData, image]);
        };
    };

    useEffect(() => {

        const newCEP = CEP.replace('.', '').replace('-', '');

        if (CEP.length === 10) {
            api.get(`https://viacep.com.br/ws/${newCEP}/json/`)
                .then(res => {
                    setEndereco(res.data.logradouro);
                    setBairro(res.data.bairro);
                    setCity(res.data.localidade);
                    setState(res.data.uf);
                })
                .catch(err => { console.log(err) });
        }
    }, [CEP]);

    useEffect(() => {
        getData();
    }, [state, city]);

    useEffect(() => {
        refreshUserData();
        valueByType(refreshProfissional(), refreshEmpresa()); userType === '0' && getProfession();
    }, []);

    const styles = createTheme({
        centralize: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        userProfile: {
            m: 'auto',
            height: 150,
            width: 150,
            borderRadius: '50%'
        },
        userProfileBox: {
            m: '2em 1em',
            p: '2em 1em',
            width: '100%',
            maxWidth: '1280px'
        },
        checkboxContainer: {
            display: 'flex',
            flexDirection: "row",
            alignItems: "center",
        },
        label: {
            margin: 10,
        },
        editButton: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'row',
            width: '100%',
            marginTop: '1.5em'
        },
        textField: {
            mt: '1.5em',
            width: '100%'
        },
        uploadBox: {
            mt: '1em',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column'
        },
        uploadButton: {
            width: '8em',
            height: '8em'
        },
        uploadText: {
            fontWeight: '700',
            textAlign: 'center',
            m: '1em'
        }
    });

    return (
        <Box sx={styles.centralize}>
            {
                console.log(userImage, defaultImageURL)
            }
            <Grid component={Card} container spacing={1} sx={styles.userProfileBox}>
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Box sx={{ bgcolor: theme.palette.primary.main, p: '.5rem', borderRadius: '50%' }}>
                        <CardMedia sx={styles.userProfile} image={userImage} />
                    </Box>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <input
                        accept="image/*"
                        type="file"
                        id="select-image"
                        style={{ display: "none" }}
                        onChange={(e) => onFileChange(e)}
                    />
                    <label htmlFor="select-image">
                        <Button fullWidth startIcon={<Edit />} color="primary" variant="contained" component="span" >
                            Editar imagem
                        </Button>
                    </label>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <Button
                        fullWidth
                        color="error" variant="contained"
                        startIcon={<Delete />}
                        onClick={() => deleteImage()}>
                        Remover imagem
                    </Button>
                </Grid>

                <Grid item xs={12}>
                    <Typography sx={{ mt: '1em' }} variant='h2'>
                        Informações {valueByType('Pessoais', 'Empresariais')}
                    </Typography>
                </Grid>

                {
                    valueByType(
                        <>
                            <Grid item xs={12} sm={6}>
                                <TextField fullWidth label="Nome" value={nome} onChange={(e) => setNome(e.target.value)} />
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <TextField fullWidth label="Nascimento" value={nascimento} onChange={(e) => setNascimento(e.target.value)} />
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <FormControl>
                                    <InputLabel>Sexo</InputLabel>
                                    <Select

                                        value={sexo}
                                        label="Sexo"
                                        onChange={e => setSexo(e.target.value)}
                                    >
                                        <MenuItem value={'M'}>Masculino</MenuItem>
                                        <MenuItem value={'F'}>Feminino</MenuItem>
                                        <MenuItem value={'No'}>Não responder</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <InputMask
                                    mask="999.999.999-99"
                                    maskChar={null}
                                    onChange={e => setCPF(e.target.value)}
                                    value={CPF}
                                >
                                    {() => <TextField
                                        variant="outlined"
                                        fullWidth
                                        label='CPF*'
                                    />}
                                </InputMask>
                            </Grid>

                            <Grid item xs={6} sm={3}>
                                <InputMask
                                    mask="9999999999"
                                    maskChar={null}
                                    onChange={e => setRG(e.target.value)}
                                    value={RG}
                                >
                                    {() => <TextField
                                        variant="outlined"
                                        fullWidth
                                        label='RG*'
                                    />}
                                </InputMask>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography sx={{ mt: '1em' }} variant='h2'>Informações Profissionais</Typography>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <TextField InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <AddLink />
                                        </InputAdornment>
                                    ),
                                }} fullWidth label="Portfólio" value={portfolio} onChange={(e) => setPortfolio(e.target.value)} />
                            </Grid>

                            {
                                userType === '0' && <>
                                    <Grid item xs={12}>
                                        <Typography sx={{ mt: '1em' }} variant='h2'>Galeria</Typography>
                                    </Grid>
                                    {
                                        galleryImages?.length <= 6 && galleryImagesURL?.length <= 5 &&
                                        <Grid component={Card} item xs={12} sx={styles.uploadBox}>
                                            <input
                                                accept="image/*"
                                                type="file"
                                                id="gallery-image"
                                                style={{ display: "none" }}
                                                onChange={(e) => onGalleryChange(e)}
                                            />
                                            <label htmlFor="gallery-image">
                                                <IconButton component="span">
                                                    <Upload sx={styles.uploadButton} color="primary" />
                                                </IconButton>
                                            </label>
                                            <Typography sx={styles.uploadText}>
                                                Insira aqui, até 6 imagens relacionadas à você e seu trabalho
                                            </Typography>
                                        </Grid>
                                    }
                                    {
                                        galleryImagesURL?.map((obj, index) => {
                                            return (
                                                <Grid item xs={4} sm={2}>
                                                    <Card sx={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                                        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'end' }}>
                                                            <IconButton onClick={() => deleteGalleryImage(index, obj.image_id)}>
                                                                <Clear sx={{ color: 'red' }} />
                                                            </IconButton>
                                                        </Box>
                                                        <CardMedia component={'img'} sx={{
                                                            borderRadius: 2, height: '10rem',
                                                            maxWidth: '80%',
                                                            mb: '1rem'
                                                        }} image={obj.image ? "https://bejobs.com.br" + obj.image : obj} />
                                                    </Card>
                                                </Grid>
                                            )
                                        })
                                    }
                                </>
                            }

                            <Grid item xs={12}>
                                <Typography sx={{ mt: '1em' }} variant='h2'>Qual sua área de atuação profissional?</Typography>
                            </Grid>

                            <Grid item xs={12}>
                                {
                                    userProfession?.length > 0 ?
                                        userProfession?.map((obj, index) => {
                                            return <Chip key={index} color="primary" sx={{ m: '.25em' }} label={obj} variant="filled" onDelete={() => changeProfessionList(obj)} />
                                        })
                                        :
                                        <Chip color="error" variant="filled" label={'Você ainda não incluiu especialidades'} />
                                }
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <FormControl fullWidth>
                                    <Select
                                        value={''}
                                        onChange={(e) => setUserProfession(oldData => [...oldData, e.target.value])}
                                        fullWidth
                                        displayEmpty
                                        MenuProps={{ PaperProps: { sx: { maxHeight: 225 } } }}
                                        inputProps={{
                                            'aria-label': 'Without label'
                                        }}
                                    >
                                        <MenuItem value={''}>- Adicionar especialidade -</MenuItem>
                                        {
                                            professions.map((obj) => {
                                                return !userProfession.includes(obj.nome) && (
                                                    <MenuItem key={obj.id} value={obj.nome}>{obj.nome}</MenuItem>
                                                );
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12}>
                                <Typography sx={{ mt: '1em' }} variant='h2'>Redes sociais</Typography>
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <TextField InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Instagram />
                                        </InputAdornment>
                                    ),
                                }} fullWidth label="Instagram" value={instagram} onChange={(e) => setInstagram(e.target.value)} />
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <TextField InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Facebook />
                                        </InputAdornment>
                                    ),
                                }} fullWidth label="Facebook" value={facebook} onChange={(e) => setFacebook(e.target.value)} />
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <TextField InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <LinkedIn />
                                        </InputAdornment>
                                    ),
                                }} fullWidth label="LinkedIn" value={linkedin} onChange={(e) => setLinkedin(e.target.value)} />
                            </Grid>
                        </>
                        ,
                        <>
                            <Grid item xs={12} sm={6}>
                                <TextField fullWidth label="Nome Fantasia" value={nome} onChange={(e) => setNome(e.target.value)} />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField fullWidth label="Razão Social" value={razaoSocial} onChange={(e) => setRazaoSocial(e.target.value)} />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <InputMask
                                    mask="99.999.999/9999-99"
                                    maskChar={null}
                                    onChange={e => setCNPJ(e.target.value)}
                                    value={CNPJ}
                                >
                                    {() => <TextField
                                        variant="outlined"
                                        fullWidth
                                        label='CNPJ*'
                                    />}
                                </InputMask>
                            </Grid>
                        </>
                    )
                }

                <Grid item xs={12}>
                    <Typography sx={{ mt: '1em' }} variant='h2'>Endereço</Typography>
                </Grid>

                <Grid item xs={6} sm={3}>
                    <InputMask
                        mask="99.999-999"
                        maskChar={null}
                        onChange={e => setCEP(e.target.value)}
                        value={CEP}
                    >
                        {() => <TextField
                            variant="outlined"
                            fullWidth
                            label='CEP*'
                        />}
                    </InputMask>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TextField fullWidth disabled={CEP === ''} label={`Endereço ${valueByType('Residencial', 'Empresarial')}`}
                        value={endereco} onChange={(e) => setEndereco(e.target.value)} />
                </Grid>

                <Grid item xs={6} sm={3}>
                    <TextField fullWidth disabled={endereco === ''} label="Número" value={number} onChange={(e) => setNumber(e.target.value)} />
                </Grid>

                <Grid item xs={6} sm={3}>
                    <TextField fullWidth disabled={number === ''} label="Complemento" value={complemento} onChange={(e) => setComplemento(e.target.value)} />
                </Grid>

                <Grid item xs={6} sm={3}>
                    <TextField fullWidth disabled={complemento === ''} label="Bairro" value={bairro} onChange={(e) => setBairro(e.target.value)} />
                </Grid>

                <Grid item xs={6} sm={2}>
                    <Select
                        fullWidth
                        disabled={bairro === ''}
                        value={state}
                        onChange={e => setState(e.target.value)}
                        MenuProps={{ PaperProps: { sx: { maxHeight: 225 } } }}
                    >
                        <MenuItem value={0}>- Todos os estados -</MenuItem>
                        {
                            stateList.map((obj, index) => {
                                return <MenuItem key={index} value={obj}>{obj}</MenuItem>
                            })
                        }
                    </Select>
                </Grid>

                <Grid item xs={6} sm={3}>
                    <Select
                        fullWidth
                        value={city}
                        onChange={e => setCity(e.target.value)}
                        disabled={state === 0}
                        MenuProps={{ PaperProps: { sx: { maxHeight: 225 } } }}
                    >
                        <MenuItem value={0}>- Todas as cidades -</MenuItem>
                        {
                            cityList.map((obj, index) => {
                                return <MenuItem key={index} value={obj}>{obj}</MenuItem>
                            })
                        }
                    </Select>
                </Grid>

                <Grid item xs={12}>
                    <Typography sx={{ mt: '1em' }} variant='h2'>Contato</Typography>
                </Grid>

                <Grid item xs={6} sm={3}>
                    <InputMask
                        mask="(99) 9 9999-9999"
                        maskChar={null}
                        onChange={e => setCelular(e.target.value)}
                        value={celular}
                    >
                        {() => <TextField
                            variant="outlined"
                            fullWidth
                            label='Telefone*'
                        />}
                    </InputMask>
                </Grid>

                <Grid item xs={6}>
                    <TextField fullWidth label="Email 1" value={email} onChange={(e) => setEmail(e.target.value)} />
                </Grid>

                <Grid item xs={12}>
                    <Typography sx={{ mt: '1em' }} variant='h2'>Senha</Typography>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TextField type='password' fullWidth label="Senha" value={senha} onChange={(e) => setSenha(e.target.value)} />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TextField type='password' fullWidth label="Confirmar Senha" value={conf_senha} onChange={(e) => setConfSenha(e.target.value)} />
                </Grid>

                <Grid item xs={12} >
                    <Button size='large' startIcon={<Edit />} color="primary" variant="contained" fullWidth sx={{ mt: '1.5em' }} onClick={() => changeUserData()}>
                        Editar cadastro
                    </Button>
                    {message && <Typography>{message}</Typography>}
                </Grid>
            </Grid>
        </Box>
    )
};