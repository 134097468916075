import { Accordion, AccordionDetails, AccordionSummary, Button, Card, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import api from "../services/api";
import { useEffect, useState } from "react";
import { Send } from "@mui/icons-material";
import AuthContext from "../contexts/AuthProvider";
import { useContext } from "react";

export default function Help() {

    const [isLoading, setIsLoading] = useState(false);

    const [faqs, setFaqs] = useState([]);
    const [categories, setCategories] = useState([]);
    const [chosenCategorie, setChosenCategorie] = useState('');

    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');

    const { userData } = useContext(AuthContext);

    function getFAQ() {
        api.get('faq.php')
            .then(res => {
                if (res.data.success) {
                    setFaqs(res.data.perguntas);
                } else {
                    alert('Houve um erro ao tentar carregar as FAQs! Tente novamente mais tarde.')
                };
            })
            .catch(err => {
                alert('Houve um erro ao tentar carregar as FAQs! Tente novamente mais tarde.')
                console.log(err);
            });
    };

    function getCategorias() {
        api.get('chamados-categoria.php')
            .then(res => {
                if (res.data.success) {
                    setCategories(res.data.chamados_categorias);
                } else {
                    alert('Houve um erro ao tentar carregar as categorias! Tente novamente mais tarde.')
                };
            })
            .catch(err => {
                alert('Houve um erro ao tentar carregar as categorias! Tente novamente mais tarde.')
                console.log(err);
            });
    };

    function resetFields() {
        setChosenCategorie('');
        setSubject('');
        setMessage('');
    };

    function createCall() {

        if (subject !== '' && chosenCategorie !== '' && message !== '') {

            setIsLoading(true);

            let data = {
                id_usuario: userData.id,
                assunto: subject,
                categoria: parseInt(chosenCategorie),
                mensagem: message
            };

            api.post('chamados-cadastro.php', data)
                .then(res => {
                    if (res.data.success) {
                        alert('Chamado criado com sucesso!');
                        resetFields();
                        setIsLoading(false);
                    } else {
                        alert('Houve um erro ao tentar criar chamado! Tente novamente mais tarde.');
                        setIsLoading(false);
                    };
                })
                .catch(err => {
                    alert('Houve um erro ao tentar criar chamado! Tente novamente mais tarde.')
                    console.log(err);
                    setIsLoading(false);
                });
        } else {
            alert('Para abrir um chamado, você deve preencher todos os campos!')
        };
    };

    useEffect(() => {
        getFAQ();
        getCategorias();
    }, []);

    const styles = {
        centralizer: {
            maxWidth: '1280px',
            p: '1em'
        },
    };

    return (
        <Grid container spacing={1} sx={styles.centralizer}>
            <Grid item xs={12}>
                <Typography variant="h1" color='white'>
                    FAQ e Área de Suporte
                </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
                <Card sx={{ p: "1rem" }}>
                    <Typography variant="h2" mb={'1rem'}>
                        FAQ
                    </Typography>
                    {
                        faqs.map((obj, index) => {
                            return <Accordion key={index}>
                                <AccordionSummary>
                                    <Typography fontWeight={700} color={"primary"} ml=".25em">
                                        {index + 1 + " - " + obj.titulo}
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        {obj.texto}
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        })
                    }
                </Card>
            </Grid>
            <Grid item xs={12} sm={6}>
                <Card sx={{ p: "1rem" }}>
                    <Typography variant="h2">
                        Suporte
                    </Typography>

                    <TextField fullWidth sx={{ mt: '1rem' }} value={subject} onChange={e => setSubject(e.target.value)} placeholder="Assunto*" />

                    <FormControl sx={{ mt: '1rem', width: '50%' }}>
                        <InputLabel>Categoria*</InputLabel>
                        <Select
                            fullWidth
                            value={chosenCategorie}
                            label="Categoria*"
                            onChange={e => setChosenCategorie(e.target.value)}
                        >
                            <MenuItem value={''}>- Selecionar Categoria -</MenuItem>
                            {categories.map(obj => {
                                return <MenuItem key={obj.id} value={obj.id}>{obj.titulo}</MenuItem>
                            })}
                        </Select>
                    </FormControl>

                    <TextField fullWidth sx={{ mt: '1rem' }} multiline rows={5} placeholder="Mensagem*" value={message} onChange={e => setMessage(e.target.value)} />

                    <Button disabled={isLoading} variant="contained" sx={{ mt: '1rem' }} startIcon={<Send />} onClick={() => createCall()}>
                        Abrir chamado
                    </Button>
                </Card>
            </Grid>
        </Grid>
    );
};