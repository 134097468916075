import api from "../services/api";

function oneSignal(id, onesignal) {

    api.post('usuarios-onesignal.php', { id_usuario: id, id_onesignal: onesignal })
        .then(res => {
            console.log(res.data);
        })
        .catch(error => {
            alert("ERRO: " + error)
        });
};


export function oneSignalPush(id) {

    if (window.cordova) {

        window.plugins.OneSignal.setAppId("ac6417c3-3feb-4ac9-b232-ae60be76ac9c");

        window.plugins.OneSignal.setNotificationOpenedHandler((jsonData) => {
            console.log('notificationOpenedCallback: ' + JSON.stringify(jsonData));
        });

        window.plugins.OneSignal.promptForPushNotificationsWithUserResponse((accepted) => {
            console.log("User accepted notifications: " + accepted);
        });

        window.plugins.OneSignal.addSubscriptionObserver((state) => {

            if (!state.from.isSubscribed && state.to.isSubscribed) {

                console.log("Subscribed for OneSignal push notifications!")
                // get player ID

                let onesignal = state.to.userId;
                oneSignal(id, onesignal);
            };
            console.log("Push Subscription state changed: " + JSON.stringify(state));
        });
    };
};