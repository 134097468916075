import { Clear, Upload } from "@mui/icons-material";
import { Box, Button, Card, CardMedia, Checkbox, createTheme, Grid, IconButton, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export default function Gallery({ back, userType, handleChangeProgress, handleChangeGallery }) {

    const [userImage, setUserImage] = useState(null);
    const [userImageURL, setUserImageURL] = useState(null);

    const [privacy, setPrivacy] = useState(null);

    const [galleryImages, setGalleryImages] = useState([]);
    const [galleryImagesURL, setGalleryImagesURL] = useState([]);

    useEffect(() => {
        handleChangeProgress(75);
    }, []);

    function userGallery() {
        if (privacy) {
            var images = {
                userImage,
                galleryImages
            };
            handleChangeGallery(images);
        } else {
            alert('Para avançar, você deve concordar com as POLÍTICAS DE PRIVACIDADE!')
        };
    };

    const styles = createTheme({
        uploadBox: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            mb: '1em'
        },
        iconArea: {
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'center',
            flexDirection: 'row',
            p: '1em'
        },
        uploadButton: {
            width: '8em',
            height: '8em'
        },
        iconButton: {
            width: '4em',
            height: '4em',
            borderRadius: '50%'
        },
        uploadText: {
            fontWeight: '700',
            textAlign: 'center',
            m: '1em'
        }
    });

    function onFileChange(e) {
        let files = e.target.files;

        setUserImageURL(URL.createObjectURL(files[0]));

        let fileReader = new FileReader();

        fileReader.readAsDataURL(files[0]);
        fileReader.onload = (event) => {
            setUserImage(event.target.result)
        }
    };

    function onGalleryChange(e) {

        let files = e.target.files;

        let image_preview = URL.createObjectURL(files[0]);

        setGalleryImagesURL(oldData => [...oldData, image_preview]);

        let fileReader = new FileReader();

        fileReader.readAsDataURL(files[0]);
        fileReader.onload = (event) => {
            let image = event.target.result

            setGalleryImages(oldData => [...oldData, image]);
        };
    };

    function deleteImage(index) {
        let images_preview = galleryImagesURL;

        let images = galleryImagesURL;

        images_preview = images_preview.filter(obj => obj !== images_preview[index]);

        images = images.filter(obj => obj !== images[index]);

        setGalleryImagesURL(images_preview);
        setGalleryImages(images);
    };

    function buttonText() {
        if (userType === '0') {
            return 'Criar conta';
        } else {
            return 'Próximo'
        };
    };

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Typography sx={{ mt: '1em' }} variant='h2'>Foto de perfil</Typography>
            </Grid>

            <Grid component={Card} item xs={12} sx={styles.iconArea}>
                <input
                    accept="image/*"
                    type="file"
                    id="select-image"
                    style={{ display: "none" }}
                    onChange={(e) => onFileChange(e)}
                />
                <label htmlFor="select-image">
                    <IconButton sx={styles.iconButton} component="span" >
                        {userImageURL ?
                            <CardMedia sx={styles.iconButton} component="img" image={userImageURL} />
                            :
                            <Upload sx={styles.iconButton} color="primary" variant="contained" />
                        }
                    </IconButton>
                </label>
                <Typography ml={'1em'}>
                    Insira aqui, sua melhor foto. Esta será visível para todos{userType === '0' && " recrutadores"}!
                </Typography>
            </Grid>
            {
                userType === '0' && <>
                    <Grid item xs={12}>
                        <Typography sx={{ mt: '1em' }} variant='h2'>Galeria</Typography>
                    </Grid>
                    {
                        galleryImages.length <= 6 && galleryImagesURL.length <= 5 &&
                        <Grid component={Card} item xs={12} sx={styles.uploadBox}>
                            <input
                                accept="image/*"
                                type="file"
                                id="gallery-image"
                                style={{ display: "none" }}
                                onChange={(e) => onGalleryChange(e)}
                            />
                            <label htmlFor="gallery-image">
                                <IconButton component="span">
                                    <Upload sx={styles.uploadButton} color="primary" />
                                </IconButton>
                            </label>
                            <Typography sx={styles.uploadText}>
                                Insira aqui, até 6 imagens relacionadas à você e seu trabalho
                            </Typography>
                        </Grid>
                    }
                    {
                        galleryImagesURL?.map((image, index) => {
                            return (
                                <Grid item xs={4} sm={2}>
                                    <Card sx={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'end' }}>
                                            <IconButton onClick={() => deleteImage(index)}>
                                                <Clear sx={{ color: 'red' }} />
                                            </IconButton>
                                        </Box>
                                        <CardMedia component={'img'} sx={{
                                            borderRadius: 2, height: '10rem',
                                            maxWidth: '80%',
                                            mb: '1rem'
                                        }} image={image} />
                                    </Card>
                                </Grid>
                            )
                        })
                    }
                </>
            }
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center', flexDirection: 'row' }}>
                <Checkbox checked={privacy} onChange={() => { setPrivacy(!privacy) }} />
                <Typography>
                    Concordo com as <Link to='/TermosEPrivacidade' style={{ textDecoration: 'none' }}><strong>Políticas de Privacidade</strong></Link>
                </Typography>
            </Grid>
            <Grid item xs={12} sx={{
                display: 'flex',
                justifyContent: 'space-between'
            }}>
                <Button onClick={() => back()}>
                    Voltar
                </Button>
                <Button size="large" variant="contained" onClick={() => userGallery()}>
                    {buttonText()}
                </Button>
            </Grid>
        </Grid>
    )
};